<template>
  <div @mouseover="handleMouseOver()" @mouseleave="handleMouseLeave()">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in imgUrl" :key="index">
        <img :src="item" alt="banner" style="width: 100%" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [{ src: "image1.jpg" }, { src: "image2.jpg" }, { src: "image3.jpg" }],
      swiperOptions: {
        loop: false,
        autoplay: false,
        initialSlide: 0,
        speed:800,
        effect: 'fade'
      },
    };
  },
  props: {
    imgUrl: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    handleMouseOver() {
      this.$refs.mySwiper.$swiper.slideTo(1); // 切换到第二张幻灯片
    },
    handleMouseLeave() {
      this.$refs.mySwiper.$swiper.slideTo(0); // 切换回第一张幻灯片
    },
  },
 
};
</script>
